<template>
    <div class="tab-pane fade active show" id="v-pills-faq" role="tabpanel" aria-labelledby="v-pills-faq-tab">
        <div class="col content">
          <form class="p-4">
            <h5>Frequently Asked Questions</h5>
          <div class="gap10"></div>
<div class="panel-group" id="accordion">
  <div class="panel panel-default">
    <div class="panel-heading">
      <h6 class="panel-title">
        <a class="text-decoration-none" data-toggle="collapse" data-parent="#accordion" href="#collapse1">
        Question 1</a>
      </h6>
    </div>
    <div id="collapse1" class="panel-collapse collapse in">
      <div class="panel-body">Lorem ipsum dolor sit amet, consectetur adipisicing elit,
      sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
      minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
      commodo consequat.</div>
    </div>
  </div>
  <div class="panel panel-default">
    <div class="panel-heading">
      <h6 class="panel-title">
        <a class="text-decoration-none" data-toggle="collapse" data-parent="#accordion" href="#collapse2">
        Question 2</a>
      </h6>
    </div>
    <div id="collapse2" class="panel-collapse collapse">
      <div class="panel-body">Lorem ipsum dolor sit amet, consectetur adipisicing elit,
      sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
      minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
      commodo consequat.</div>
    </div>
  </div>
  <div class="panel panel-default">
    <div class="panel-heading">
      <h6 class="panel-title">
        <a class="text-decoration-none" data-toggle="collapse" data-parent="#accordion" href="#collapse3">
        Question 3</a>
      </h6>
    </div>
    <div id="collapse3" class="panel-collapse collapse">
      <div class="panel-body">Lorem ipsum dolor sit amet, consectetur adipisicing elit,
      sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
      minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
      commodo consequat.</div>
    </div>
  </div>
</div>
          </form>
        </div>
      </div>
</template>

<script>
import '../assets/hire.css';
export default {
    name:'FAQ'
}
</script>